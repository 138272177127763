<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Pengujian Kalibrasi'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nomor_ppj_label"
                  label="Nomor PPJ :"
                  label-for="nomor_ppj"
                >
                  <b-form-input
                    id="nomor_ppj"
                    v-model="$v.form.nomor_ppj.$model"
                    type="text"
                    placeholder="Enter Text..."
                    :state="validateState('nomor_ppj')"
                  ></b-form-input>

                  <b-form-invalid-feedback id="nomor_ppj_feedback">
                    Nomor PPJ Harus Terisi.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="tanggal_label"
                  label="Tanggal :"
                  label-for="tanggal"
                >
                  <b-form-input id="tanggal" v-model="form.tanggal" type="date"></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nama_barang_label"
                  label="Nama Barang :"
                  label-for="nama_barang"
                >
                  <b-form-input
                    id="nama_barang"
                    v-model="$v.form.nama_barang.$model"
                    type="text"
                    placeholder="Enter Text..."
                    :state="validateState('nama_barang')"
                  ></b-form-input>

                  <b-form-invalid-feedback id="nama_barang_feedback">
                    Nama Barang Harus Terisi.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="jumlah_label"
                  label="Jumlah :"
                  label-for="jumlah"
                >
                  <b-form-input
                    id="jumlah"
                    v-model="form.jumlah"
                    type="number"
                    placeholder="Enter Number..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="satuan_label"
                  label="Satuan :"
                  label-for="satuan"
                >
                  <b-form-input
                    id="satuan"
                    v-model="form.satuan"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/pengujiankalibrasi')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  name: 'addpabrik',
  data() {
    return {
      form: {
        nomor_ppj: null,
        tanggal: null,
        nama_barang: null,
        jumlah: null,
        satuan: null,
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 0,
      filter: null,
      filterOn: [],

      loading: false,
      id: 0,
    }
  },
  validations: {
    form: {
      nomor_ppj: {
        required,
      },
      nama_barang: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Pengujian Kalibrasi', route: '/pengujiankalibrasi' },
      { title: 'Edit' },
    ])
  },
  created() {
    var self = this
    this.id = this.$route.params.id

    ApiService.get('/pengujiankalibrasi/edit/' + self.id)
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.form.nomor_ppj = data.data.nomor_ppj
          self.form.tanggal = data.data.tanggal
          self.form.nama_barang = data.data.nama_barang
          self.form.jumlah = data.data.jumlah
          self.form.satuan = data.data.satuan
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      // event.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      var self = this
      self.loading = true
      const nomor_ppj = self.$v.form.nomor_ppj.$model
      const tanggal = self.form.tanggal
      const nama_barang = self.$v.form.nama_barang.$model
      const jumlah = self.form.jumlah
      const satuan = self.form.satuan

      ApiService.post('/pengujiankalibrasi/update/' + self.id, {
        nomor_ppj: nomor_ppj,
        tanggal: tanggal,
        nama_barang: nama_barang,
        jumlah: jumlah,
        satuan: satuan,
      })
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.push('/pengujiankalibrasi')
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
